import { CrmUserEndpoint } from 'common-module/user';
import { map } from 'rxjs';

import { MembershipDto } from './membership/membership-dto';
import { InviteUsersResponse } from './response/invite-users-response';
import { UserModel } from './model/user.model';

export class UserEndpoint extends CrmUserEndpoint<UserModel> {
  invite(ids: string[]) {
    return this.request<InviteUsersResponse[]>('POST', 'invite', {
      body: { userIds: ids },
    });
  }

  createMembership(id: string, body: MembershipDto) {
    return this.request<UserModel>('POST', `${id}/membership`, {
      body,
    }).pipe(map((response) => this.responseTransformer.transform(response)));
  }

  deleteMembership(id: string, params: MembershipDto) {
    return this.request<UserModel>('DELETE', `${id}/membership`, {
      params,
    }).pipe(map((response) => this.responseTransformer.transform(response)));
  }
}
