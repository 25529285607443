import { UserMembership } from './membership';

/**
 * Function which checks if database user membership model is cohort non-member membership
 *
 * @param membership
 */
export const isCohortNonMemberMembership = (
  membership: UserMembership,
): boolean => {
  return membership.type === 'cohort' && membership.role !== 'member';
};

/**
 * Function which checks if database user membership model is cohort member membership
 *
 * @param membership
 */
export const isCohortMemberMembership = (
  membership: UserMembership,
): boolean => {
  return membership.type === 'cohort' && membership.role === 'member';
};

/**
 * Function which checks if database user membership model is cohort membership for current organization
 *
 * @param membership
 * @param organization
 */
export const isCohortMembershipForOrganization = (
  membership: UserMembership,
  organization: string,
): boolean => {
  return (
    isCohortMemberMembership(membership) &&
    membership.organization?._id === organization
  );
};
