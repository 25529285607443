import { inject, Injectable, isDevMode } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { isNil } from 'lodash-es';
import {
  GaActionEnum,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NGX_GTAG_FN,
} from 'ngx-google-analytics';

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  protected readonly settings = inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN);
  protected readonly _gtag = inject(NGX_GTAG_FN);

  config(options: CrmDictionary) {
    this.gtag('config', this.settings.trackingCode, options);
  }

  set(options: CrmDictionary) {
    this.gtag('set', options);
  }

  event(action: GaActionEnum | string, options?: CrmDictionary) {
    const { category, label, value, interaction, ...rest } = options ?? {};
    try {
      const opt = new Map<string, unknown>();
      if (category) {
        opt.set('event_category', category);
      }

      if (label) {
        opt.set('event_label', label);
      }

      if (value) {
        opt.set('value', value);
      }

      if (!isNil(interaction)) {
        opt.set('interaction', interaction);
      }

      this.fillOptions(opt, rest);
      const params = this.toParams(opt);
      if (params) {
        this.gtag('event', action, params);
      } else {
        this.gtag('event', action);
      }
    } catch (error) {
      this.throw(error);
    }
  }

  private throw(err: unknown) {
    if (
      (this.settings.enableTracing || isDevMode()) &&
      console &&
      console.error
    ) {
      console.error(err);
    }
  }

  private toParams(
    map: Map<string, unknown>,
  ): { [param: string]: unknown } | void {
    return Array.from(map).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {} as CrmDictionary);
  }

  private fillOptions(map: Map<string, unknown>, options?: CrmDictionary) {
    Object.entries({ ...(options ?? {}) }).map(([key, value]) =>
      map.set(key, value),
    );
  }

  private gtag(...args: (string | CrmDictionary)[]) {
    try {
      this._gtag(...args.filter((x) => x !== undefined));
    } catch (err) {
      this.throw(err);
    }
  }
}
