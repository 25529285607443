import { CrmEndpointListResponse } from 'common-module/endpoint';

export const getEmptyResponse = <Response>(
  limit: number = 50,
): CrmEndpointListResponse<Response> => {
  return {
    data: [],
    skip: 0,
    limit,
    total: 0,
  };
};
