import { Injectable } from '@angular/core';

const JWT = '__bhv_jwt';

@Injectable({ providedIn: 'root' })
export class JwtService {
  _token!: string | null;

  constructor() {
    this._token = localStorage.getItem(JWT);
  }

  get token() {
    return this._token;
  }

  setToken(token: string) {
    this._token = token;
    localStorage.setItem(JWT, token);
  }

  resetToken() {
    this._token = null;
    localStorage.removeItem(JWT);
  }
}
